import React from "react";
// Components
import SecondaryLanding from "../../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo";

const FinancialNewsArticle1 = () => {
  const title = "WaFd Bank President and CEO Brent Beardall in Stable Condition";
  const description =
    "Washington Federal (Nasdaq: WAFD) reports that on January 2, 2023, the President and CEO of Washington Federal, Inc. and Washington Federal Bank (dba WaFd Bank), Brent Beardall, was one of four people on board a private plane that crashed in Provo, Utah shortly after takeoff.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content:
          "https://www.wafdbank.com/about-us/investor-relations/financial-news/brent-beardall-in-stable-condition"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-financial-news-02-250.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/investor-relations",
      title: "Investor Relations"
    },
    {
      id: 3,
      url: "/about-us/investor-relations/financial-news",
      title: "Financial News"
    },
    {
      id: 4,
      active: true,
      title: "Brent Beardall in stable condition"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h1>{title}</h1>
            <h3>January 3, 2023</h3>
            <p>
              Washington Federal (Nasdaq: WAFD) reports that on January 2, 2023, the President and CEO of Washington
              Federal, Inc. and Washington Federal Bank (dba WaFd Bank), Brent Beardall, was one of four people on board
              a private plane that crashed in Provo, Utah shortly after takeoff. He was transported by ambulance to the
              hospital with broken bones and lacerations, underwent surgery later that day, and is expected to make a
              full recovery. Mr. Beardall will take a temporary leave of absence from his duties as President and CEO
              while he recovers, and Executive Vice President and Chief Consumer Banker, Cathy Cooper, will temporarily
              assume Mr. Beardall's duties. The Company will provide additional updates as they become available.
            </p>

            <p>
              Washington Federal's Chairman of the Board, Stephen Graham, said, &ldquo;We want to express our
              condolences for the family of Nathan Ricks, who died in this tragic accident. All of us at WaFd are
              grateful that Brent is expected to make a full recovery and look forward to his return to normal duties
              shortly. We have every confidence in our strong management team's ability to move forward with the
              execution of the Bank's strategy.
            </p>
            <p>
              Washington Federal Bank, a federally insured Washington state-chartered bank with headquarters in Seattle,
              Washington, has more than 200 branches in nine western states. To find out more about WaFd Bank, please
              visit our website{" "}
              <a id="news-wafd-bank-link" href="/">
                www.wafdbank.com
              </a>
              . WaFd Bank uses its website to distribute financial and other material information about the Company.
            </p>
            <p>
              <strong>Contact:</strong>
              <br />
              Brad Goode
              <br />
              Chief Marketing & Communications Officer
              <br />
              WaFd Bank/Washington Federal, Inc.
              <br />
              425 Pike Street, Seattle, WA 98101
              <br />
              <a id="brad-goode-tel-link" href="tel:206-626-8178">
                (206) 626-8178
              </a>
            </p>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};
export default FinancialNewsArticle1;
